import styled from '@emotion/styled';
import { useLanguage } from '../context/LanguageContext';

const SwitcherContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const LanguageButton = styled.button<{ active: boolean }>`
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.primary};
  background: ${props => props.active ? props.theme.colors.primary : 'transparent'};
  color: ${props => props.active ? 'white' : props.theme.colors.primary};
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: ${props => props.theme.colors.primary};
    color: white;
  }
`;

const LanguageSwitcher = () => {
  const { language, setLanguage } = useLanguage();

  return (
    <SwitcherContainer>
      <LanguageButton 
        active={language === 'en'} 
        onClick={() => setLanguage('en')}
      >
        EN
      </LanguageButton>
      <LanguageButton 
        active={language === 'de'} 
        onClick={() => setLanguage('de')}
      >
        DE
      </LanguageButton>
    </SwitcherContainer>
  );
};

export default LanguageSwitcher; 