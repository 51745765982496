import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { FaGithub, FaLinkedin, FaTwitter, FaEnvelope } from 'react-icons/fa';
import { Container } from './shared/StyledComponents';
import { useLanguage } from '../context/LanguageContext';

const FooterWrapper = styled.footer`
  background: rgba(0, 0, 0, 0.3);
  padding: 4rem 0 2rem 0;
  position: relative;
`;

const FooterContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
  margin-bottom: 3rem;
`;

const FooterSection = styled.div`
  color: ${props => props.theme.colors.text};
`;

const FooterTitle = styled.h4`
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
`;

const FooterText = styled.p`
  opacity: 0.8;
  line-height: 1.6;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const SocialIcon = styled(motion.a)`
  color: ${props => props.theme.colors.text};
  font-size: 1.5rem;
  opacity: 0.8;
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

const QuickLinks = styled.ul`
  list-style: none;
  padding: 0;
`;

const QuickLink = styled.li`
  margin-bottom: 0.8rem;
  
  a {
    color: ${props => props.theme.colors.text};
    text-decoration: none;
    opacity: 0.8;
    transition: color 0.3s ease;

    &:hover {
      color: ${props => props.theme.colors.primary};
    }
  }
`;

const Copyright = styled.div`
  text-align: center;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: ${props => props.theme.colors.text};
  opacity: 0.8;
`;

const Footer = () => {
  const { t } = useLanguage();
  const currentYear = new Date().getFullYear();

  return (
    <FooterWrapper>
      <Container>
        <FooterContent>
          <FooterSection>
            <FooterTitle>{t('footer.about.title')}</FooterTitle>
            <FooterText>
              {t('footer.about.description')}
            </FooterText>
            <SocialLinks>
              <SocialIcon 
                href="https://github.com" 
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ y: -3 }}
              >
                <FaGithub />
              </SocialIcon>
              <SocialIcon 
                href="https://linkedin.com" 
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ y: -3 }}
              >
                <FaLinkedin />
              </SocialIcon>
              <SocialIcon 
                href="https://twitter.com" 
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ y: -3 }}
              >
                <FaTwitter />
              </SocialIcon>
              <SocialIcon 
                href="mailto:contact@nexilabs.com"
                whileHover={{ y: -3 }}
              >
                <FaEnvelope />
              </SocialIcon>
            </SocialLinks>
          </FooterSection>

          <FooterSection>
            <FooterTitle>{t('footer.quickLinks.title')}</FooterTitle>
            <QuickLinks>
              <QuickLink>
                <a href="#home">{t('footer.quickLinks.home')}</a>
              </QuickLink>
              <QuickLink>
                <a href="#about">{t('footer.quickLinks.about')}</a>
              </QuickLink>
              <QuickLink>
                <a href="#process">{t('footer.quickLinks.process')}</a>
              </QuickLink>
              <QuickLink>
                <a href="#contact">{t('footer.quickLinks.contact')}</a>
              </QuickLink>
            </QuickLinks>
          </FooterSection>

          <FooterSection>
            <FooterTitle>{t('footer.contact.title')}</FooterTitle>
            <FooterText>
              {t('footer.contact.email')}: contact@nexilabs.com<br />
              {t('footer.contact.phone')}: +1 (555) 123-4567<br />
              {t('footer.contact.address')}: Your Business Address
            </FooterText>
          </FooterSection>
        </FooterContent>

        <Copyright>
          {t('footer.copyright').replace('{year}', currentYear.toString())}
          {`© ${currentYear} Nexi Labs. All rights reserved.`}
        </Copyright>
      </Container>
    </FooterWrapper>
  );
};

export default Footer; 