import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const Section = styled.section`
  min-height: 100vh;
  padding: 100px 50px;
  position: relative;
  overflow: hidden;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 80px 20px;
  }
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
`;

export const Title = styled(motion.h2)`
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 2rem;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: 2rem;
  }
`;

export const Subtitle = styled(motion.h3)`
  font-size: 1.8rem;
  color: ${props => props.theme.colors.text};
  margin-bottom: 1.5rem;
`;

export const GlowingBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 50% 50%, 
    rgba(29, 78, 216, 0.08), 
    rgba(245, 158, 11, 0.08), 
    transparent 60%);
  z-index: 1;
`;

export const Card = styled(motion.div)`
  background: white;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
`; 