import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Section, Container } from './shared/StyledComponents';
import netmesh from '../assets/netmesh.png'; // Import the network mesh image
import { useLanguage } from '../context/LanguageContext';
import { Link } from 'react-scroll'; // Add this import

const HeroSection = styled(Section)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh; // Ensures full viewport height
  padding-top: 120px; // Increased padding from top
  padding-bottom: 60px; // Added bottom padding for balance
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    135deg,
    #1a237e 0%, /* Deep blue */
    #0d47a1 50%, /* Rich blue */
    #1e3c72 100% /* Navy blue */
  );
`;

const NetworkMesh = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${netmesh});
  background-size: cover;
  background-position: center 20%;
  opacity: 0.7;
  mix-blend-mode: screen;
`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at 50% 50%,
    rgba(0, 0, 0, 0.3), // Darker center
    rgba(0, 0, 0, 0.1) 70% // Fade to transparent
  );
`;

const HeroContent = styled(motion.div)`
  max-width: 800px;
  position: relative;
  z-index: 2;
  padding: 3rem 2rem; // Increased vertical padding
  margin-top: 1rem; // Added margin from top
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.2); // Subtle dark backdrop
  backdrop-filter: blur(5px); // Slight blur effect
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
`;

const MainTitle = styled(motion.h1)`
  font-size: 3rem;
  color: #ffffff;
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  font-weight: 700;
  line-height: 1.2;
  
  span {
    color: ${props => props.theme.colors.secondary};
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    font-weight: 800;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: 2rem;
  }
`;

const HeroText = styled(motion.p)`
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.95);
  margin-bottom: 2.5rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  line-height: 1.6;
  font-weight: 500;
`;

const CTAButton = styled(motion.div)`
  display: inline-block;
  padding: 1rem 2.2rem;
  font-size: 1.1rem;
  background: linear-gradient(45deg, 
    ${props => props.theme.colors.secondary},
    #ff9800
  );
  border: none;
  border-radius: 30px;
  color: #1a237e;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);

  &:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
  }
`;

const Hero = () => {
  const { t } = useLanguage();
  
  return (
    <HeroSection id="home">
      <NetworkMesh />
      <GradientOverlay />
      <Container>
        <HeroContent
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <MainTitle>
            {t('hero.welcome')} <span>Nexi Labs</span>
            <br />
            {t('hero.subtitle')}
          </MainTitle>
          <HeroText>
            {t('hero.description')}
          </HeroText>
          <Link
            to="contact"
            smooth={true}
            duration={800}
            offset={-50}
          >
            <CTAButton
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {t('hero.cta')}
            </CTAButton>
          </Link>
        </HeroContent>
      </Container>
    </HeroSection>
  );
};

export default Hero; 