import styled from '@emotion/styled';
import { Link } from 'react-scroll';
import { useState } from 'react';
import logo from '../assets/logo.png';
import LanguageSwitcher from './LanguageSwitcher';
import { useLanguage } from '../context/LanguageContext';

const Nav = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(29, 78, 216, 0.15);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  z-index: 1000;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 0 20px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const LogoImage = styled.img`
  height: 42px;
  width: auto;
  object-fit: contain;
`;

const LogoText = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: #4B5563;
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.5px;
`;

const NavLinks = styled.div<{ isOpen: boolean }>`
  display: flex;
  gap: 30px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    position: fixed;
    top: 70px; // Height of navbar
    right: ${props => props.isOpen ? '0' : '-100%'};
    flex-direction: column;
    background: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
    height: calc(100vh - 70px);
    width: 250px;
    padding: 2rem;
    gap: 20px;
    transition: right 0.3s ease;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  }
`;

const NavLink = styled(Link)`
  color: ${props => props.theme.colors.text};
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 8px 12px;
  border-radius: 6px;
  
  &:hover {
    color: ${props => props.theme.colors.primary};
    background: rgba(29, 78, 216, 0.1);
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    text-align: left;
    padding: 1rem;
  }
`;

const HamburgerButton = styled.button`
  display: none;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 8px;
    
    svg {
      width: 24px;
      height: 24px;
      transition: all 0.3s ease;
      
      &:hover {
        transform: scale(1.1);
        color: ${props => props.theme.colors.primary};
      }
    }
  }
`;

const Overlay = styled.div<{ isOpen: boolean }>`
  display: none;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: block;
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    opacity: ${props => props.isOpen ? 1 : 0};
    visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
    transition: all 0.3s ease;
    z-index: 999;
  }
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useLanguage();

  return (
    <>
      <Nav>
        <LogoWrapper>
          <LogoImage src={logo} alt="Nexi Labs Logo" />
          <LogoText>Nexi Labs</LogoText>
        </LogoWrapper>
        
        <HamburgerButton 
          onClick={() => setIsOpen(!isOpen)} 
          aria-label="Menu"
        >
          <svg 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2"
          >
            <path 
              d="M4 6h16M4 12h16M4 18h16" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            />
          </svg>
        </HamburgerButton>

        <NavLinks isOpen={isOpen}>
          <NavLink to="home" smooth={true} duration={500} onClick={() => setIsOpen(false)}>
            {t('nav.home')}
          </NavLink>
          <NavLink to="about" smooth={true} duration={500} onClick={() => setIsOpen(false)}>
            {t('nav.about')}
          </NavLink>
          <NavLink to="process" smooth={true} duration={500} onClick={() => setIsOpen(false)}>
            {t('nav.process')}
          </NavLink>
          <NavLink to="contact" smooth={true} duration={500} onClick={() => setIsOpen(false)}>
            {t('nav.contact')}
          </NavLink>
          <LanguageSwitcher />
        </NavLinks>
      </Nav>
      <Overlay isOpen={isOpen} onClick={() => setIsOpen(false)} />
    </>
  );
};

export default Navbar; 