export const translations = {
  en: {
    nav: {
      home: 'Home',
      about: 'About',
      process: 'Process',
      contact: 'Contact'
    },
    hero: {
      welcome: 'Welcome to',
      subtitle: 'Where Innovation Meets Web Solutions',
      description: 'Transform your digital presence with our end-to-end web development solutions. From concept to deployment, we make your web dreams reality.',
      cta: 'Get Started'
    },
    about: {
      title: 'About Us',
      description: 'At Nexi Labs, we specialize in creating powerful, modern web solutions that help businesses thrive in the digital age. Our comprehensive approach ensures your success from start to finish.',
      services: {
        webdev: {
          title: 'Web Development',
          description: 'Custom websites built with modern technologies and best practices.'
        },
        deployment: {
          title: 'Deployment & Hosting',
          description: 'Secure and reliable hosting solutions with continuous deployment.'
        },
        seo: {
          title: 'SEO Optimization',
          description: 'Boost your online visibility with our proven SEO strategies.'
        },
        maintenance: {
          title: 'Maintenance & Support',
          description: 'Ongoing support and updates to keep your website running smoothly.'
        }
      }
    },
    process: {
      title: 'Our Process',
      steps: {
        planning: {
          title: '1. Planning & Strategy',
          description: 'We begin with understanding your goals, target audience, and requirements. This phase includes project scope definition, timeline planning, and technical strategy development.'
        },
        design: {
          title: '2. Design & Wireframing',
          description: 'Creating intuitive user interfaces and engaging user experiences. We design responsive layouts that look great on all devices while maintaining your brand identity.'
        },
        development: {
          title: '3. Development',
          description: 'Our developers bring designs to life using modern technologies and best practices. We ensure clean, maintainable code that performs well and scales with your needs.'
        },
        deployment: {
          title: '4. Deployment & Hosting',
          description: 'We handle the technical aspects of launching your website, including server setup, domain configuration, and deployment automation.'
        },
        seo: {
          title: '5. SEO Optimization',
          description: 'Implementing SEO best practices to improve your website\'s visibility in search engines, including metadata optimization, performance tuning, and content strategy.'
        },
        maintenance: {
          title: '6. Maintenance & Security',
          description: 'Ongoing support, regular updates, security monitoring, and performance optimization to keep your website running smoothly.'
        }
      }
    },
    footer: {
      about: {
        title: 'About Nexi Labs',
        description: 'We\'re passionate about creating exceptional web experiences that help businesses thrive in the digital world. Our expertise spans from development to deployment and beyond.'
      },
      quickLinks: {
        title: 'Quick Links',
        home: 'Home',
        about: 'About Us',
        process: 'Our Process',
        contact: 'Contact'
      },
      contact: {
        title: 'Contact Info',
        email: 'Email',
        phone: 'Phone',
        address: 'Address'
      },
      copyright: '© {year} Nexi Labs. All rights reserved.'
    },
    contact: {
      title: 'Get In Touch',
      name: 'Name',
      email: 'Email',
      message: 'Message',
      send: 'Send Message',
      sending: 'Sending...',
      spamError: 'Please wait a minute before sending another message.',
      errorMessage: 'Failed to send message. Please try again.',
      successMessage: 'Message sent successfully! We\'ll get back to you soon.'
    }
  },
  de: {
    nav: {
      home: 'Startseite',
      about: 'Über uns',
      process: 'Prozess',
      contact: 'Kontakt'
    },
    hero: {
      welcome: 'Willkommen bei',
      subtitle: 'Wo Innovation auf Weblösungen trifft',
      description: 'Transformieren Sie Ihre digitale Präsenz mit unseren End-to-End-Webentwicklungslösungen. Von der Konzeption bis zur Bereitstellung machen wir Ihre Web-Träume wahr.',
      cta: 'Loslegen'
    },
    about: {
      title: 'Über uns',
      description: 'Bei Nexi Labs sind wir darauf spezialisiert, leistungsstarke, moderne Weblösungen zu erstellen, die Unternehmen im digitalen Zeitalter erfolgreich machen. Unser umfassender Ansatz gewährleistet Ihren Erfolg von Anfang bis Ende.',
      services: {
        webdev: {
          title: 'Webentwicklung',
          description: 'Maßgeschneiderte Websites, entwickelt mit modernen Technologien und Best Practices.'
        },
        deployment: {
          title: 'Bereitstellung & Hosting',
          description: 'Sichere und zuverlässige Hosting-Lösungen mit kontinuierlicher Bereitstellung.'
        },
        seo: {
          title: 'SEO-Optimierung',
          description: 'Steigern Sie Ihre Online-Sichtbarkeit mit unseren bewährten SEO-Strategien.'
        },
        maintenance: {
          title: 'Wartung & Support',
          description: 'Laufende Unterstützung und Updates, um Ihre Website reibungslos am Laufen zu halten.'
        }
      }
    },
    process: {
      title: 'Unser Prozess',
      steps: {
        planning: {
          title: '1. Planung & Strategie',
          description: 'Wir beginnen mit dem Verständnis Ihrer Ziele, Zielgruppe und Anforderungen. Diese Phase umfasst Projektumfangsdefinition, Zeitplanung und technische Strategieentwicklung.'
        },
        design: {
          title: '2. Design & Wireframing',
          description: 'Erstellung intuitiver Benutzeroberflächen und ansprechender Benutzererlebnisse. Wir gestalten responsive Layouts, die auf allen Geräten großartig aussehen und Ihre Markenidentität bewahren.'
        },
        development: {
          title: '3. Entwicklung',
          description: 'Unsere Entwickler erwecken Designs mit modernen Technologien und Best Practices zum Leben. Wir sorgen für sauberen, wartbaren Code, der gut funktioniert und mit Ihren Anforderungen skaliert.'
        },
        deployment: {
          title: '4. Bereitstellung & Hosting',
          description: 'Wir kümmern uns um die technischen Aspekte der Website-Einführung, einschließlich Server-Setup, Domain-Konfiguration und Bereitstellungsautomatisierung.'
        },
        seo: {
          title: '5. SEO-Optimierung',
          description: 'Implementierung von SEO-Best-Practices zur Verbesserung der Sichtbarkeit Ihrer Website in Suchmaschinen, einschließlich Metadata-Optimierung, Performance-Tuning und Content-Strategie.'
        },
        maintenance: {
          title: '6. Wartung & Sicherheit',
          description: 'Fortlaufender Support, regelmäßige Updates, Sicherheitsüberwachung und Leistungsoptimierung, um Ihre Website reibungslos am Laufen zu halten.'
        }
      }
    },
    footer: {
      about: {
        title: 'Über Nexi Labs',
        description: 'Wir sind leidenschaftlich daran interessiert, außergewöhnliche Web-Erlebnisse zu schaffen, die Unternehmen in der digitalen Welt erfolgreich machen. Unsere Expertise reicht von der Entwicklung bis zur Bereitstellung und darüber hinaus.'
      },
      quickLinks: {
        title: 'Schnelllinks',
        home: 'Startseite',
        about: 'Über uns',
        process: 'Unser Prozess',
        contact: 'Kontakt'
      },
      contact: {
        title: 'Kontaktinfo',
        email: 'E-Mail',
        phone: 'Telefon',
        address: 'Adresse'
      },
      copyright: '© {year} Nexi Labs. Alle Rechte vorbehalten.'
    },
    contact: {
      title: 'Kontaktieren Sie uns',
      name: 'Name',
      email: 'E-Mail',
      message: 'Nachricht',
      send: 'Nachricht senden',
      sending: 'Wird gesendet...',
      spamError: 'Bitte warten Sie eine Minute, bevor Sie eine weitere Nachricht senden.',
      errorMessage: 'Nachricht konnte nicht gesendet werden. Bitte versuchen Sie es erneut.',
      successMessage: 'Nachricht erfolgreich gesendet! Wir melden uns bald bei Ihnen.'
    }
  }
}; 