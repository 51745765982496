import { ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import { lazy, Suspense } from 'react';
import Footer from './components/Footer';
import { theme } from './theme/theme';
import { LanguageProvider } from './context/LanguageContext';
import { HelmetProvider } from 'react-helmet-async';
import SEO from './components/SEO';

const AppContainer = styled.div`
  background: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.text};
  min-height: 100vh;
`;

// Loading component
const LoadingFallback = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  font-size: 1.2rem;
  color: ${props => props.theme.colors.primary};
`;

// Lazy load non-critical components
const About = lazy(() => import('./components/About'));
const Process = lazy(() => import('./components/Process'));
const Contact = lazy(() => import('./components/Contact'));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LanguageProvider>
        <HelmetProvider>
          <AppContainer>
            <SEO 
              title="Nexi Labs - Modern Web Development Solutions"
              description="Transform your digital presence with Nexi Labs. We specialize in creating powerful, modern web solutions that help businesses thrive in the digital age."
              keywords="web development, react, typescript, modern web, SEO optimization, Nexi Labs"
            />
            <header role="banner">
              <nav role="navigation" aria-label="Main navigation">
                <Navbar />
              </nav>
            </header>
            <main role="main">
              <article>
                <Hero />
                <Suspense fallback={<LoadingFallback>Loading...</LoadingFallback>}>
                  <About />
                  <Process />
                  <Contact />
                </Suspense>
              </article>
            </main>
            <footer role="contentinfo">
              <Footer />
            </footer>
          </AppContainer>
        </HelmetProvider>
      </LanguageProvider>
    </ThemeProvider>
  );
}

export default App;
