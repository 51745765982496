export const theme = {
    colors: {
        primary: '#3B82F6',    // Bright Blue
        secondary: '#0EA5E9',  // Sky Blue
        background: '#E5E7EB', // Light Cool Gray
        text: '#1C1E21',       // Dark Charcoal
        accent: '#EF4444',     // Bright Red
    },
    breakpoints: {
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1200px',
      },
}; 